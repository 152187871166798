import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class AppsGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        let roles = route.data["roles"] as Array<string>;
        console.log(roles);


        let UrbanpointAdmin = JSON.parse(localStorage.getItem('UrbanpointAdmin'));
        if (UrbanpointAdmin && UrbanpointAdmin.auth_key != null) 
        {
            if(UrbanpointAdmin.name == '1')
            {
                return true;
            }
            if (UrbanpointAdmin.name == '2' && roles[0] == 'other' || UrbanpointAdmin.name == '3' && roles[0] == 'other')
            {
                return true;
            }
            else
            {
                return false;
            }
        }
        this.router.navigate(['/auth/login']);
        return false;
    }
}

