export const appConfig =
{
    // // Staging
    base_url: 'https://api-mtc.veroke.com/',
    file_url: 'https://api-mtc.veroke.com/uploads/',

    //Live
    // base_url: 'http://18.185.217.28/up_qatar/api/v1/',
    // file_url: 'http://18.185.217.28/up_qatar/uploads/',


    // // ADminUrban
    // base_url: 'https://adminurban.com/up_qatar/api/v1/',
    // file_url: 'https://adminurban.com/up_qatar/uploads/',

    base_url_slug: 'partners/',

    default_auth_key: 'UP!and$',
    // google_api_key: 'AIzaSyBWA0K0LkWH5Q2owT6Kh7uHBO-pJl7o7nk',
    // google_api_key: 'AIzaSyAn5pLKFcSFwQWkMLNcDZEReqxf8hp-pxY'
    google_api_key: 'AIzaSyDykNkzGorhQSSUDKkJUzSYFMK04_YeHQ4'
}
