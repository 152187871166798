import {Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {MainService} from '../../services/main.service';
import {FormGroup, Validators, FormBuilder, FormControl} from "@angular/forms";
import * as moment from 'moment';
import {AlertDialog} from "../../lib/alert.dialog";
import {MatDialog} from "@angular/material";
import { Subscription } from 'rxjs';


declare var $: any;


@Component({
    selector: 'new-request',
    templateUrl: 'new-request.component.html',
    styleUrls: ['new-request.component.scss'],
})
export class NewRequest implements OnInit {

    Form: FormGroup;
    // addShipmentDatesForm: FormGroup;

    sample_type: any;
    bar_code: any;
    min_temperature: any;
    max_temperature: any;
    sample_quantity: any;
    storage_type: any;

    OrderItems: any = [];


    currentPage: any = 1;
    perPage: number;
    index: any = 1;
    totalPages: number;

    stageCheck = 1;

    Addresses: any[] = [];

    dropoff_address: any = '';
    pickup_address: any = '';

    urgency: any = 'normal';

    DropoffLocation: string;
    PickupLocation: string;

    dropOff: any = {
        address: 'Select Address',
        person_name: 'Select Address',
        person_phone: 'Select Address'
    };
    pickUp: any = {
        address: 'Select Address',
        person_name: 'Select Address',
        person_phone: 'Select Address'
    };
    orderTime: any;
    orderDate: any;

	sub: Subscription;
    id: any;
	isEditing: boolean;


    constructor(private router: Router, private mainService: MainService, protected _route: ActivatedRoute, protected formbuilder: FormBuilder, protected dialog: MatDialog)
    {
        this.isEditing = false;

        this.Form = this.formbuilder.group({
            // partner_id: [null],
            pickup_latitude: [null],
            pickup_longitude: [null],
            pickup_address: [null],
            pickup_phone: [null],
            pickup_name: [null],

            dropoff_latitude: [null],
            dropoff_longitude: [null],
            dropoff_address: [null],
            dropoff_phone: [null],
            dropoff_name: [null],

            urgency: ['urgent'],

            pickup_date: [null],
            pickup_time: [null],
            items: [],
        });

    }

    ngOnInit()
    {
        this.getAddressList();

		this.sub = this._route.params.subscribe(params => {
            this.id = params['id'];
            console.log("this.id ", this.id);
			if (this.id != 'add')
			{
				this.isEditing = true;
				this.Form.addControl('id', new FormControl(this.id));
				this.onViewOrder();
			}
			else
			{
				this.isEditing = false;
			}
		});
	}

	onViewOrder(): void
	{
		let url = 'view-orderitems?order_id=' + this.id;
		this.mainService.getList(url).then(result =>
		{
			console.log(result)
			if (result.status === 200 && result.data)
			{
				let data = result.data[0];

				this.OrderItems = data.order_items;

				this.dropOff = {
					address: data.dropoff_address,
					person_name: data.dropoff_name,
					person_phone: data.dropoff_phone
				};

				this.pickUp = {
					address: data.pickup_address,
					person_name: data.pickup_name,
					person_phone: data.pickup_phone
				};

				this.orderTime = new Date(data.pickup_date +' '+ data.pickup_time);
				this.orderDate = data.pickup_date;

				this.dropoff_address = data.dropoff_address;

				this.pickup_address = data.pickup_address;

				this.urgency = data.urgency;

				// this.getControlName('partner_id').setValue(data.partner_id);

				this.PickupLocation = data.pickup_latitude + ',' + data.pickup_longitude;
				this.DropoffLocation = data.dropoff_latitude + ',' + data.dropoff_longitude;

				this.getControlName('pickup_latitude').setValue(this.pickUp.latitude);
				this.getControlName('pickup_longitude').setValue(this.pickUp.longitude);
				this.getControlName('pickup_address').setValue(this.pickUp.address);
				this.getControlName('pickup_phone').setValue(this.pickUp.person_phone);
				this.getControlName('pickup_name').setValue(this.pickUp.person_name);

				this.getControlName('dropoff_latitude').setValue(this.dropOff.latitude);
				this.getControlName('dropoff_longitude').setValue(this.dropOff.longitude);
				this.getControlName('dropoff_address').setValue(this.dropOff.address);
				this.getControlName('dropoff_phone').setValue(this.dropOff.person_phone);
				this.getControlName('dropoff_name').setValue(this.dropOff.person_name);

				let oD = moment(this.orderDate).format('YYYY-MM-DD');
				this.getControlName('pickup_date').setValue(oD);
				let oT = moment(this.orderTime).format('HH:mm:ss');
				this.getControlName('pickup_time').setValue(oT);

				this.getControlName('urgency').setValue(this.urgency);
			}
			else
			{

			}
		});
	}

    getAddressList(): void
    {
        let url = 'view-location';
        this.mainService.getList(url).then(response =>
        {
            if (response.status == 200 || response.status === 201)
            {
                this.Addresses = response.data;
            }
            else
            {
                this.Addresses = [];
            }
        })
    }

    getControlName(name)
    {
        return this.Form.get(name);
    }

    getControlValue(name)
    {
        return this.Form.get(name).value;
    }

    onNext()
	{
		if (this.stageCheck != 4)
		{
			this.stageCheck++;
		}
		if (this.stageCheck == 2)
		{
			this.getAddressList();
		}
	}

	onPrevious()
	{
		if (this.stageCheck != 1)
		{
			this.stageCheck--;
		}
		if (this.stageCheck == 2)
		{
			this.getAddressList();
		}
	}

	onAddItems(): void
	{
        let data = {
            sample_type: this.sample_type,
            min_temperature: this.min_temperature,
            max_temperature: this.max_temperature,
            sample_quantity: this.sample_quantity,
            storage_type: this.storage_type,
			bar_code: this.bar_code
		}

        if(this.containsSameObject(data, this.OrderItems))
        {
            let dialogRef = this.dialog.open(AlertDialog, {autoFocus: false});
            let cm = dialogRef.componentInstance;
            cm.heading = 'Alert';
            cm.message = 'Item is already added';
            cm.cancelButtonText = 'Close';
            cm.type = 'info';
        }
        else
        {
            this.OrderItems.push(data);
            this.Form.get('items').setValue(this.OrderItems);
        }

        this.sample_type = null;
        this.min_temperature = null;
        this.max_temperature = null;
        this.sample_quantity = null;
        this.storage_type = null;
        this.bar_code = null;
    }

    onEditItem(item, i): void
    {
        this.OrderItems.splice(i, 1);

        this.sample_type = item.sample_type;
        this.min_temperature = item.min_temperature;
        this.max_temperature = item.max_temperature;
        this.sample_quantity = item.sample_quantity;
        this.storage_type = item.storage_type;
		this.bar_code = item.bar_code;

        this.Form.get('items').setValue(this.OrderItems);
    }

    // onDeletItem(item, i): void
    // {
    //     this.OrderItems.splice(i, 1);
    //     this.Form.get('items').setValue(this.OrderItems);
    // }

    onDeletItem(item, i): void
	{
		if(this.isEditing)
		{
			let dialogRef = this.dialog.open(AlertDialog, {autoFocus: false});
			let cm = dialogRef.componentInstance;
			cm.heading = 'Delete Item';
			cm.message = 'Are you sure to Delete Item?';
			cm.submitButtonText = 'Yes';
			cm.cancelButtonText = 'No';
			cm.type = 'ask';
			cm.methodType = 'get';
			cm.methodName = 'delete-orderitem?id='+ item.id;
			cm.showLoading = true;

			dialogRef.afterClosed().subscribe(result => {
				if(result)
				{
					this.OrderItems.splice(i, 1);
					this.Form.get('items').setValue(this.OrderItems);
				}
			})
		}
		else
		{
			this.OrderItems.splice(i, 1);
			this.Form.get('items').setValue(this.OrderItems);
		}
	}

    containsSameObject(obj: any, list: any)
    {
        if(list.length == 0)
        {
            return false
        }

        for (var x in list)
        {
            if (JSON.stringify(list[x]) === JSON.stringify(obj)) {
                return true;
            }
        }

        return false;
    }


    onPickupChange(addressId: any): void
    {
        const result = this.Addresses.filter(add => addressId === add.id);
        this.pickUp = result[0];
        this.PickupLocation = result[0].latitude + ',' + result[0].longitude;

        this.getControlName('pickup_latitude').setValue(this.pickUp.latitude);
        this.getControlName('pickup_longitude').setValue(this.pickUp.longitude);
        this.getControlName('pickup_address').setValue(this.pickUp.address);
        this.getControlName('pickup_phone').setValue(this.pickUp.person_phone);
        this.getControlName('pickup_name').setValue(this.pickUp.person_name);
    }

    onDropoffChange(addressId: any): void
    {
        const result = this.Addresses.filter(add => addressId === add.id);
        this.dropOff = result[0];
        this.DropoffLocation = result[0].latitude + ',' + result[0].longitude;

        this.getControlName('dropoff_latitude').setValue(this.dropOff.latitude);
        this.getControlName('dropoff_longitude').setValue(this.dropOff.longitude);
        this.getControlName('dropoff_address').setValue(this.dropOff.address);
        this.getControlName('dropoff_phone').setValue(this.dropOff.person_phone);
        this.getControlName('dropoff_name').setValue(this.dropOff.person_name);
    }

    onSelectDate(): void
    {
        let oD = moment(this.orderDate).format('YYYY-MM-DD');
        this.getControlName('pickup_date').setValue(oD);
    }

    onSelectTime(): void
    {
        let oT = moment(this.orderTime).format('HH:mm:ss');
        this.getControlName('pickup_time').setValue(oT);
    }

    onUrgency(type: any): void
    {
        this.getControlName('urgency').setValue(type);
    }

    onConfirmOrder(): void
    {
        let url = '';
		if (this.id == 'add')
		{
			url = 'add-orderwith-item';
		}
		else
		{
			url = 'update-orderwith-item';
        }

        this.mainService.postData(url, this.Form.value).then(response =>
        {
            if (response.status == 200 || response.status === 201)
            {
                this.router.navigateByUrl('/main/pending-orders');
            }
            else
            {
                let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                let cm = dialogRef.componentInstance;
                cm.heading = 'Error';
                cm.message = response.error.error.message;
                cm.cancelButtonText = 'Ok';
                cm.type = 'error';
            }
        },
        Error => {
            let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
            let cm = dialogRef.componentInstance;
            cm.heading = 'Error';
            cm.message = "Internal Server Error.";
            cm.cancelButtonText = 'Ok';
            cm.type = 'error';
        });
    }

    onDisabledCheck()
    {
        if(this.stageCheck == 1 && this.OrderItems.length == 0)
        {
            return true;
        }
        else if(this.stageCheck == 2 && (this.pickup_address == "" || this.dropoff_address == "" ))
        {
            return true;
        }
        else if(this.stageCheck == 3 && (this.orderDate == null || this.orderTime == null))
        {
            return true;
        }
        else
        {
            return false;
        }
    }
}
