import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { MainService, BaseLoaderService, PaginationService } from '../../services';


@Component({
	selector: 'app-dashboard',
	templateUrl: 'dashboard.component.html'
})
export class DashboardComponent implements OnInit
{
	constructor(protected router: Router,
		protected _route: ActivatedRoute,
		protected mainApiService: MainService,
		protected paginationService: PaginationService)
	{

	}
	
    ngOnDestroy(): void 
	{
	}
	

	ngOnInit() 
	{

	}
}
