
import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from '../../services/main.service';
import { appConfig } from '../../../config';


declare var $: any;

@Component({
	selector: 'card-app',
	templateUrl: 'card.component.html',
	styleUrls: ['card.component.scss'],
})
export class Card implements OnInit {

	@Input() logo : any;
	@Input() heading : any;
	@Input() bg_color : any;

	constructor(protected mainApiService: MainService, private router: Router) {

	}

	ngOnInit() {

	}

}